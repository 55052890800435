var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-16"},[_c('div',{staticClass:"flex"},[_c('InputToggle',{attrs:{"label":_vm.$t('carrent.DifferentLocation')},model:{value:(_vm.differentLocation),callback:function ($$v) {_vm.differentLocation=$$v},expression:"differentLocation"}})],1),_vm._v(" "),_c('div',{staticClass:"flex flex-wrap md:flex-row mt-16 max-input-row"},[_c('div',{staticClass:"flex flex-wrap w-full md:w-1/2 md:pr-8 lg:pr-0"},[_c('InputCarRentalNamesearch',{staticClass:"sm:w-2/3 md:w-full",attrs:{"label":_vm.differentLocation
            ? _vm.$t('carrent.NameSearchFrom')
            : _vm.$t('carrent.NameSearchFromTo'),"placeholder":_vm.differentLocation
            ? _vm.$t('carrent.NameSearchFrom')
            : _vm.$t('carrent.NameSearchFromTo'),"input-style":[
          'truncate',
          'border-r',
          _vm.differentLocation ? 'lg:rounded-r-none lg:border-r-0' : ''
        ],"error":_vm.fromSearchError,"location":'pickup',"component-attr-id":'ta-car-rent-from-search'},model:{value:(_vm.fromSearch),callback:function ($$v) {_vm.fromSearch=$$v},expression:"fromSearch"}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.differentLocation),expression:"differentLocation"}],staticClass:"flex flex-wrap w-full md:w-1/2 mt-16 md:mt-0 md:pl-8 lg:pl-0"},[_c('InputCarRentalNamesearch',{staticClass:"sm:w-2/3 md:w-full",attrs:{"label":_vm.$t('carrent.NameSearchTo'),"placeholder":_vm.$t('carrent.NameSearchTo'),"error":_vm.toSearchError,"input-style":['truncate', 'border-r', 'lg:rounded-l-none'],"location":'drop',"component-attr-id":'ta-car-rent-to-search'},model:{value:(_vm.toSearch),callback:function ($$v) {_vm.toSearch=$$v},expression:"toSearch"}})],1)]),_vm._v(" "),(_vm.showButtons)?_c('div',[_c('div',{staticClass:"mt-16 inline-block",on:{"click":_vm.addCarRent}},[_c('ButtonSmall',{attrs:{"type":"primary"}},[_vm._v("\n        "+_vm._s(_vm.$t('general.NewTravelAssistantService'))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"inline-block"},[_c('button',{staticClass:"link font-bold ml-8",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n        "+_vm._s(_vm.$t('general.Cancel'))+"\n      ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }