var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"switch cursor-pointer",class:{
    error: _vm.hasError,
    disabled: _vm.isDisabled,
    dark: _vm.isDark,
    active: _vm.isActive
  },on:{"click":function($event){!_vm.isDisabled ? _vm.$emit('input', !_vm.value) : ''}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isActive),expression:"isActive"}],attrs:{"type":"checkbox","disabled":_vm.isDisabled},domProps:{"checked":Array.isArray(_vm.isActive)?_vm._i(_vm.isActive,null)>-1:(_vm.isActive)},on:{"change":function($event){var $$a=_vm.isActive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isActive=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isActive=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isActive=$$c}}}}),_vm._v(" "),_c('label',{staticClass:"text-sm cursor-pointer",class:_vm.hasError
        ? 'text-danger'
        : _vm.isDisabled
        ? 'text-grey'
        : _vm.isDark
        ? 'text-white'
        : 'text-black'},[_vm._v(_vm._s(_vm.label))])])}
var staticRenderFns = []

export { render, staticRenderFns }