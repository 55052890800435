var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-input relative",attrs:{"id":_vm.componentAttrIdAutosuggest}},[_c('label',{staticClass:"block text-black text-xs font-bold leading-12 mb-5",class:{
      'text-danger': _vm.error.length > 0,
      'text-grey-dark opacity-50': _vm.isDisabled,
      'text-black': !_vm.isDark && _vm.error.length === 0 && !_vm.isDisabled,
      'text-white': _vm.isDark && !_vm.isDisabled && _vm.error.length === 0
    },attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label))]),_vm._v(" "),_c('input',_vm._g(_vm._b({staticClass:"w-full h-48 leading-16 text-base py-14 mb-1 px-12 rounded text-black hover:border-primary focus:outline-none focus:border-primary",class:_vm.getInputClasses(),attrs:{"type":_vm.internal_inputProps.type,"autocomplete":_vm.internal_inputProps.autocomplete,"role":"combobox","aria-autocomplete":"list","aria-owns":"autosuggest__results","aria-activedescendant":_vm.isOpen && _vm.currentIndex !== null
        ? ("autosuggest__results-item--" + _vm.currentIndex)
        : '',"aria-haspopup":_vm.isOpen ? 'true' : 'false',"aria-expanded":_vm.isOpen ? 'true' : 'false',"disabled":_vm.isDisabled},domProps:{"value":_vm.internalValue},on:{"input":_vm.inputHandler,"keydown":_vm.handleKeyStroke,"focus":function($event){return $event.target.select()}}},'input',_vm.internal_inputProps,false),_vm.listeners)),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"pointer-events-none loading text-2x absolute bottom-0 right-0 mb-10 mr-12"},[_c('i',{staticClass:"animate-spin fas fa-spinner-third"})]),_vm._v(" "),_c('div',{class:_vm.componentAttrClassAutosuggestResultsContainer},[(_vm.isOpen)?_c('div',{class:_vm.componentAttrClassAutosuggestResults,attrs:{"aria-labelledby":_vm.componentAttrIdAutosuggest}},[_vm._t("before-suggestions"),_vm._v(" "),_vm._l((_vm.computedSections),function(cs,key){return _c(cs.type,{key:_vm.getSectionRef(key),ref:_vm.getSectionRef(key),refInFor:true,tag:"component",attrs:{"current-index":_vm.currentIndex,"normalize-item-function":_vm.normalizeItem,"render-suggestion":_vm.renderSuggestion,"section":cs},on:{"updateCurrentIndex":_vm.updateCurrentIndex},scopedSlots:_vm._u([{key:("before-section-" + (cs.name || cs.label)),fn:function(ref){
        var section = ref.section;
        var className = ref.className;
return [_vm._t(("before-section-" + (cs.name || cs.label)),null,{"section":section,"className":className})]}},{key:"default",fn:function(ref){
        var suggestion = ref.suggestion;
        var _key = ref._key;
return [_vm._t("default",[_c('div',{staticClass:"flex flex-row justify-start py-8"},[_c('div',{staticClass:"mx-8 text-center"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(suggestion.item.type === 'city'),expression:"suggestion.item.type === 'city'"}],staticClass:"fal fa-map-marker-alt text-grey w-18"}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(suggestion.item.type === 'poi'),expression:"suggestion.item.type === 'poi'"}],staticClass:"fal fa-landmark text-grey w-18"}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(suggestion.item.type === 'hotel'),expression:"suggestion.item.type === 'hotel'"}],staticClass:"fal fa-hospital text-grey w-18"}),_vm._v(" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(suggestion.item.type === 'airport'),expression:"suggestion.item.type === 'airport'"}],staticClass:"fas fa-plane text-grey w-18"})]),_vm._v(" "),_c('div',{staticClass:"w-auto flex items-center",class:suggestion.item.destination ? 'flex-col' : ''},[_c('div',{staticClass:"leading-16 pr-24 w-full",domProps:{"innerHTML":_vm._s(_vm.markText(suggestion.item.name))}}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(suggestion.item.destination),expression:"suggestion.item.destination"}],staticClass:"text-xs leading-12 mt-2 w-full",domProps:{"innerHTML":_vm._s(_vm.markText(suggestion.item.destination))}})])])],{"suggestion":suggestion,"index":_key})]}},{key:("after-section-" + (cs.name || cs.label)),fn:function(ref){
        var section = ref.section;
return [_vm._t(("after-section-" + (cs.name || cs.label)),null,{"section":section})]}},{key:"after-section",fn:function(ref){
        var section = ref.section;
return [_vm._t("after-section",null,{"section":section})]}}],null,true)})}),_vm._v(" "),_vm._t("after-suggestions")],2):_vm._e(),_vm._v(" "),_vm._t("after-suggestions-container")],2),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.error.length > 0),expression:"error.length > 0"}],staticClass:"block text-danger text-xs leading-12"},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }