export const vueI18n = {"fallbackLocale":"en"}
export const vueI18nLoader = false
export const locales = [{"code":"en","iso":"en","file":"en.js"},{"code":"hu","iso":"hu","file":"hu.js"},{"code":"cs","iso":"cs","file":"cs.js"},{"code":"de","iso":"de","file":"de.js"},{"code":"ro","iso":"ro","file":"ro.js"},{"code":"ru","iso":"ru","file":"ru.js"},{"code":"sk","iso":"sk","file":"sk.js"},{"code":"es","iso":"es","file":"es.js"},{"code":"pl","iso":"pl","file":"pl.js"},{"code":"tr","iso":"tr","file":"tr.js"}]
export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix'
export const lazy = true
export const langDir = 'lang/'
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"alwaysRedirect":true,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false}
export const differentDomains = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const skipSettingLocaleOnNavigate = false
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = false
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DIR_KEY = 'dir'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["en","hu","cs","de","ro","ru","sk","es","pl","tr"]
export const trailingSlash = undefined
