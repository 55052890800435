var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-body min-h-screen flex flex-col justify-between\n   text-grey-dark"},[(false)?_c('div',{staticClass:"w-full p-16 xl:px-40 bg-primary text-white flex flex-row justify-center text-3x"},[_c('div',{staticClass:"text-center mr-5"},[_vm._v(_vm._s(_vm.$t('general.TopCounterText')))]),_vm._v(" "),_c('CountDown',{staticClass:"text-center font-bold",attrs:{"end-date":'2022-05-04 10:00'}})],1):_vm._e(),_vm._v(" "),_c('NotificationBar'),_vm._v(" "),_c('Navbar'),_vm._v(" "),_c('nuxt'),_vm._v(" "),_c('Footer'),_vm._v(" "),(!_vm.browserSupported)?_c('InformativeModal',{directives:[{name:"show",rawName:"v-show",value:(_vm.browserNotSupportedModalActive),expression:"browserNotSupportedModalActive"}],attrs:{"accept-button":'OK',"link-button":''},on:{"accept":function($event){_vm.browserNotSupportedModalActive = false}}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_vm._v("\n      "+_vm._s(_vm.$t('general.BrowserNotSupported'))+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.getPopup)?_c('InformativeModal',{attrs:{"accept-button":_vm.getPopup.acceptText !== undefined ? _vm.getPopup.acceptText : null,"link-button":_vm.getPopup.disableCancel == undefined ? _vm.$t('general.Cancel') : ''},on:{"cancel":function($event){return _vm.$store.commit('general/setPopup', false)},"accept":function($event){_vm.getPopup.url !== undefined
        ? (_vm.$router.push(_vm.localePath(_vm.getPopup.url)),
          _vm.$store.commit('general/setPopup', false))
        : _vm.$store.commit('general/setPopup', false)}}},[_c('div',{staticClass:"mb-16",attrs:{"slot":"body"},slot:"body"},[(_vm.getPopup.title !== undefined)?_c('div',{staticClass:"flex w-full text-black mb-12"},[_c('div',{staticClass:"flex flex-grow text-lg font-bold leading-24"},[_vm._v("\n          "+_vm._s(_vm.getPopup.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('i',{staticClass:"fas fa-times opacity-50 cursor-pointer",on:{"click":function($event){return _vm.$store.commit('general/setPopup', false)}}})])]):_vm._e(),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.getPopup.message)+"\n      ")])])]):_vm._e(),_vm._v(" "),(_vm.getSnackbar)?_c('Snackbar',{attrs:{"message":_vm.getSnackbar !== false &&
      _vm.getSnackbar !== undefined &&
      _vm.getSnackbar.message !== undefined
        ? _vm.getSnackbar.message
        : '',"bg-color":_vm.getSnackbar.bgColor,"undo-button":_vm.getSnackbar.undoButton,"undo-event":_vm.getSnackbar.undoEvent}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }