var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"fixed top-0 left-0 w-full flex z-30 h-screen",class:[
      _vm.isFullPage
        ? 'bg-white md:bg-black-opaque'
        : 'justify-center bg-black-opaque',
      _vm.overflowYWrapper ? 'overflow-y-auto' : '',
      _vm.isTopAligned ? 'pt-48 pb-48' : !_vm.isSuperPopup ? 'items-center' : '',
      _vm.isSuperPopup ? 'overflow-y-auto items-start' : ''
    ],on:{"click":_vm.outsideClick}},[_c('div',{ref:"modalContainer",staticClass:"flex flex-wrap bg-white w-full rounded p-0",class:[
        _vm.isFullPage ? 'p-0' : 'mx-16 ' + _vm.defaultPadding,
        _vm.isTopAligned && _vm.innerWidth >= 740 ? 'h-177' : ''
      ],style:({ maxWidth: _vm.modalMaxWidth, height: _vm.modalHeight })},[_c('div',{class:_vm.isFullPage
            ? 'fixed top-0 left-0 r-0 w-full bg-white z-10'
            : 'w-full'},[_vm._t("header",[_vm._v("\n          default header\n        ")])],2),_vm._v(" "),_c('div',{staticClass:"w-full h-full",class:[
          _vm.overflowY ? (!_vm.isSuperPopup ? 'overflow-y-auto' : '') : '',
          _vm.isFullPage ? 'h-vfull' : ''
        ]},[_vm._t("body",[_vm._v("\n          default body\n        ")])],2),_vm._v(" "),_c('div',{staticClass:"fixed bottom-0 w-full"},[_vm._t("footer",[_vm._v("\n          default footer\n          "),_c('button',{staticClass:"modal-default-button",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("\n            OK\n          ")])])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }