<template>
  <div>
    <nuxt />
  </div>
</template>

<script>
export default {
  name: 'Blank',
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {}
}
</script>

<style>
center {
  min-height: 100vh;
}
</style>
