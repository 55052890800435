var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"countdown"},[(
      _vm.remaining.days.value > 0 ||
        (_vm.remaining.years.value > 0 && _vm.remaining.days.value == 0)
    )?_c('span',[(_vm.remaining.years.value != 0)?_c('span',[_vm._v(_vm._s(_vm.remaining.years.value)+" "+_vm._s(_vm.remaining.years.label))]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.remaining.days.value < 10 ? '0' + _vm.remaining.days.value : _vm.remaining.days.value)+"\n      "+_vm._s(_vm.remaining.days.label))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.remaining.hours.value < 10 ? '0' + _vm.remaining.hours.value : _vm.remaining.hours.value)+"\n      "+_vm._s(_vm.remaining.hours.label))])]):_c('span',[_c('span',[_vm._v(_vm._s(_vm.remaining.hours.value < 10
          ? '0' + _vm.remaining.hours.value
          : _vm.remaining.hours.value)+"\n      :")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.remaining.minutes.value < 10
          ? '0' + _vm.remaining.minutes.value
          : _vm.remaining.minutes.value)+"\n      :")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.remaining.seconds.value < 10
        ? '0' + _vm.remaining.seconds.value
        : _vm.remaining.seconds.value))])])])}
var staticRenderFns = []

export { render, staticRenderFns }