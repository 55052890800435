const middleware = {}

middleware['auth_needed'] = require('../middleware/auth_needed.js')
middleware['auth_needed'] = middleware['auth_needed'].default || middleware['auth_needed']

middleware['exclusive_offers_enabled'] = require('../middleware/exclusive_offers_enabled.js')
middleware['exclusive_offers_enabled'] = middleware['exclusive_offers_enabled'].default || middleware['exclusive_offers_enabled']

middleware['non_auth_only'] = require('../middleware/non_auth_only.js')
middleware['non_auth_only'] = middleware['non_auth_only'].default || middleware['non_auth_only']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['weboffice_permission'] = require('../middleware/weboffice_permission.js')
middleware['weboffice_permission'] = middleware['weboffice_permission'].default || middleware['weboffice_permission']

export default middleware
