var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-0 w-full transition-height duration-500 ease-in-out h-0 overflow-hidden bg-black",class:[
    _vm.travelAssistantVisible ? 'show-travel-assistant' : '',
    _vm.travelAssistants.length === 0 ? 'hide-travel-assistant' : '',
    _vm.travelAssistantVisible && _vm.travelAssistants.length > 0
      ? _vm.countActiveService > 0
        ? 'service-num-' + _vm.countActiveService
        : ''
      : ''
  ],attrs:{"id":"travel-assistant"}},[_c('div',{staticClass:"bg-white opacity-90"},[_c('div',{staticClass:"w-full bg-black "},[_c('div',{staticClass:"flex flex-wrap px-16 xl:px-40 max-w-screen-xl mx-auto py-3"},[_c('div',{staticClass:"flex items-center flex-grow"},[(_vm.travelAssistants.length > 1)?_c('div',[_c('i',{staticClass:"fas fa-chevron-left text-primary cursor-pointer",on:{"click":function($event){return _vm.showItem('previous')}}}),_vm._v(" "),_c('span',{staticClass:"text-pink px-8"},[_vm._v(_vm._s(_vm.getTravelAssistantPosition())+"/"+_vm._s(_vm.travelAssistants.length))]),_vm._v(" "),_c('i',{staticClass:"fas fa-chevron-right text-primary cursor-pointer",on:{"click":function($event){return _vm.showItem('next')}}})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"flex items-center block float-right"},[_c('ButtonSmall',{attrs:{"type":"primary","is-disabled":_vm.travelAssistants.length >= 5},on:{"click":_vm.goToNewTravelAssistantForm}},[_c('i',{staticClass:"fas fa-plus mr-4"}),_vm._v(_vm._s(_vm.$t('general.NewTravelAssistant'))+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"flex items-center block ml-24 opacity-50 cursor-pointer"},[_c('i',{staticClass:"fas fa-times text-white",on:{"click":function($event){return _vm.$emit('showTravelAssistant')}}})])])])]),_vm._v(" "),_c('div',{staticClass:"bg-black"},[_c('div',{staticClass:"flex flex-wrap text-sm py-16"},_vm._l((_vm.travelAssistants),function(travelAssistant,key){return _c('TravelAssistantItem',{key:key,class:[
          _vm.opacityClass,
          _vm.actualItem.id === travelAssistant.id ? 'visible' : 'invisible'
        ],attrs:{"id":travelAssistant.id,"travel-assistant":travelAssistant,"show":_vm.actualItem.id === travelAssistant.id,"updating":_vm.updating},on:{"showTravelAssistant":function($event){return _vm.$emit('showTravelAssistant')},"removeTravelAssistant":_vm.removeTravelAssistant}})}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }