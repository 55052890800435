export default {
  getProgramNameSearch(context, params, options) {
    return this.$api.$post('program/v2/text', params, options)
  },
  initProgramSearch(context, params, options) {
    return this.$api.$post('program/v2/search', params, options)
  },
  getProgramSearch(context, params, options) {
    return this.$api.$post('program/v2/results', params, options)
  },
  getProgramDetails(context, params, options) {
    return this.$api.$post('program/v2/details', params, options)
  },
  getProgramReviews(context, params, options) {
    return this.$api.$post('program/v2/reviews', params, options)
  },
  getProgramOffers(context, params, options) {
    console.log('api')
    return this.$api.$post('program/v2/offers', params, options)
  },
  getProgramFilters(context, params, options) {
    return this.$api.$post('program/v2/filters', params, options)
  },
  addToCart(context, params, options) {
    return this.$api.$post('program/v2/cart', params, options)
  },
  getBookingQuestions(context, params, options) {
    return this.$api.$post('program/v2/getbookingquestions', params, options)
  },
  payCart(context, params, options) {
    return this.$api.$post('program/v2/pay', params, options)
  },
  cancel(context, params, options) {
    return this.$api.$post('program/v2/cancel', params, options)
  },
  paymentStatus(context, params, options) {
    return this.$api.$post('program/v2/payment-status', params, options)
  }
}
