export default {
  countryCodesFullName: {
    PR: 'Puerto Rico',
    PS: 'Palestinian Territory, Occupied',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AN: 'Netherlands Antilles',
    AO: 'Angola',
    AQ: 'Antarctica',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    RE: 'Reunion',
    AU: 'Australia',
    AW: 'Aruba',
    AZ: 'Azerbaijan',
    RO: 'Romania',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    RS: 'Serbia',
    BD: 'Bangladesh',
    BE: 'Belgium',
    RU: 'Russian Federation',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    RW: 'Rwanda',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    BR: 'Brazil',
    SC: 'Seychelles',
    BS: 'Bahamas',
    BT: 'Bhutan',
    SE: 'Sweden',
    BV: 'Bouvet Island',
    SG: 'Singapore',
    BW: 'Botswana',
    SH: 'Saint Helena',
    SI: 'Slovenia',
    BY: 'Belarus',
    SJ: 'Svalbard and Jan Mayen',
    BZ: 'Belize',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    CA: 'Canada',
    SR: 'Suriname',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo, The Democratic Republic Of The',
    ST: 'Sao Tome and Principe',
    SU: 'Sudan',
    CF: 'Central African Republic',
    SV: 'El Salvador',
    CG: 'Congo, Republic of the',
    CH: 'Switzerland',
    CI: "Cote d'Ivoire",
    SY: 'Syrian Arab Republic',
    SZ: 'Swaziland',
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China, mainland',
    CO: 'Colombia',
    CR: 'Costa Rica',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    CU: 'Cuba',
    TF: 'French Southern Territories',
    CV: 'Cape Verde',
    TG: 'Togo',
    TH: 'Thailand',
    CX: 'Christmas Island',
    CY: 'Northern Cyprus',
    TJ: 'Tajikistan',
    CZ: 'Czech Republic',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    DE: 'Germany',
    TV: 'Tuvalu',
    TW: 'Taiwan (Republic of China)',
    DJ: 'Djibouti',
    TZ: 'Tanzania, United Republic Of',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    UA: 'Ukraine',
    UG: 'Uganda',
    DZ: 'Algeria',
    UM: 'United States Minor Outlying Islands',
    EC: 'Ecuador',
    US: 'United States',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Vatican City State',
    ER: 'Eritrea',
    VC: 'Saint Vincent and the Grenadines',
    ES: 'Spain',
    ET: 'Ethiopia',
    VE: 'Venezuela',
    VG: 'Virgin Islands, British',
    VN: 'Viet Nam',
    VU: 'Vanuatu',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands',
    FM: 'Micronesia, Federated States of',
    FO: 'Faroe Islands',
    FR: 'France',
    WF: 'Wallis and Futuna',
    GA: 'Gabon',
    GB: 'United Kingdom',
    WS: 'Samoa',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island and McDonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    YE: 'Yemen',
    HU: 'Hungary',
    ID: 'Indonesia',
    YT: 'Mayotte',
    IE: 'Ireland, Republic of',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    ZA: 'South Africa',
    IQ: 'Iraq',
    IR: 'Iran, Islamic Republic of',
    IS: 'Iceland',
    IT: 'Italy',
    ZM: 'Zambia',
    JE: 'Jersey',
    ZW: 'Zimbabwe',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: "Korea, Democratic People's Republic of",
    KR: 'Korea, Republic of',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: "Lao People's Democratic Republic",
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    'GE-AB': 'Abkhazia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libyan Arab Jamahiriya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova, Republic of',
    ME: 'Montenegro',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia, The Former Yugoslav Republic of',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OD: 'Southern Sudan',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PN: 'Pitcairn'
  },
  countryIdFullName: {
    230: 'UY',
    110: 'KE',
    231: 'UZ',
    111: 'KI',
    232: 'VU',
    112: 'KP',
    233: 'VE',
    113: 'KR',
    234: 'VN',
    114: 'KW',
    235: 'VG',
    115: 'KG',
    236: 'WF',
    116: 'LA',
    237: 'EH',
    117: 'LV',
    238: 'YE',
    118: 'LB',
    239: 'ZM',
    119: 'LS',
    10: 'AR',
    11: 'AM',
    12: 'AW',
    13: 'AU',
    14: 'AT',
    15: 'AZ',
    16: 'BS',
    17: 'BH',
    18: 'BD',
    19: 'BB',
    240: 'ZW',
    120: 'LR',
    241: 'JE',
    121: 'LY',
    242: 'GE-AB',
    1: 'AF',
    122: 'LI',
    243: 'CY',
    2: 'AL',
    123: 'LT',
    3: 'DZ',
    124: 'LU',
    4: 'AS',
    125: 'MO',
    5: 'AD',
    126: 'MK',
    6: 'AO',
    127: 'MG',
    7: 'AI',
    128: 'MW',
    8: 'AQ',
    129: 'MY',
    9: 'AG',
    20: 'BY',
    21: 'BE',
    22: 'BZ',
    23: 'BJ',
    24: 'BM',
    25: 'BT',
    26: 'BO',
    27: 'BA',
    28: 'BW',
    29: 'BV',
    130: 'MV',
    131: 'ML',
    132: 'MT',
    133: 'MH',
    134: 'MQ',
    135: 'MR',
    136: 'MU',
    137: 'YT',
    138: 'MX',
    139: 'FM',
    30: 'BR',
    31: 'IO',
    32: 'BN',
    33: 'BG',
    34: 'BF',
    35: 'BI',
    36: 'KH',
    37: 'CM',
    38: 'CA',
    39: 'CV',
    140: 'MD',
    141: 'MC',
    142: 'MN',
    143: 'ME',
    144: 'MS',
    145: 'MA',
    146: 'MZ',
    147: 'MM',
    148: 'NA',
    149: 'NR',
    40: 'KY',
    41: 'CF',
    42: 'TD',
    43: 'CL',
    44: 'CN',
    45: 'CX',
    46: 'CC',
    47: 'CO',
    48: 'KM',
    49: 'CG',
    150: 'NP',
    151: 'NL',
    152: 'AN',
    153: 'NC',
    154: 'NZ',
    155: 'NI',
    156: 'NE',
    157: 'NG',
    158: 'NU',
    159: 'NF',
    50: 'CD',
    51: 'CK',
    52: 'CR',
    53: 'CI',
    54: 'HR',
    55: 'CU',
    56: 'CY',
    57: 'CZ',
    58: 'DK',
    59: 'DJ',
    160: 'MP',
    161: 'NO',
    162: 'OM',
    163: 'PK',
    164: 'PW',
    165: 'PS',
    166: 'PA',
    167: 'PG',
    168: 'PY',
    169: 'PE',
    60: 'DM',
    61: 'DO',
    62: 'EC',
    63: 'EG',
    64: 'SV',
    65: 'GQ',
    66: 'ER',
    67: 'EE',
    68: 'ET',
    69: 'FK',
    170: 'PH',
    171: 'PN',
    172: 'PL',
    173: 'PT',
    174: 'PR',
    175: 'QA',
    176: 'RE',
    177: 'RO',
    178: 'RU',
    179: 'RW',
    70: 'FO',
    71: 'FJ',
    72: 'FI',
    73: 'FR',
    74: 'GF',
    75: 'PF',
    76: 'TF',
    77: 'GA',
    78: 'GM',
    79: 'GE',
    180: 'SH',
    181: 'KN',
    182: 'LC',
    183: 'PM',
    184: 'VC',
    185: 'WS',
    186: 'SM',
    187: 'ST',
    188: 'SA',
    189: 'SN',
    80: 'DE',
    81: 'GH',
    82: 'GI',
    83: 'GR',
    84: 'GL',
    85: 'GD',
    86: 'GP',
    87: 'GU',
    88: 'GT',
    89: 'GW',
    190: 'RS',
    191: 'SC',
    192: 'SL',
    193: 'SG',
    194: 'SK',
    195: 'SI',
    196: 'SB',
    197: 'SO',
    198: 'ZA',
    199: 'GS',
    90: 'GY',
    91: 'HT',
    92: 'HM',
    93: 'VA',
    94: 'HN',
    95: 'HK',
    96: 'HU',
    97: 'IS',
    98: 'IN',
    99: 'ID',
    200: 'OD',
    201: 'ES',
    202: 'LK',
    203: 'SU',
    204: 'SR',
    205: 'SJ',
    206: 'SZ',
    207: 'SE',
    208: 'CH',
    209: 'SY',
    210: 'TW',
    211: 'TJ',
    212: 'TZ',
    213: 'TH',
    214: 'TL',
    215: 'TG',
    216: 'TK',
    217: 'TO',
    218: 'TT',
    219: 'TN',
    220: 'TR',
    100: 'IR',
    221: 'TM',
    101: 'IQ',
    222: 'TC',
    102: 'IE',
    223: 'TV',
    103: 'IM',
    224: 'UG',
    104: 'IL',
    225: 'UA',
    105: 'IT',
    226: 'AE',
    106: 'JM',
    227: 'GB',
    107: 'JP',
    228: 'US',
    108: 'JO',
    229: 'UM',
    109: 'KZ'
  }
}
